import React from 'react';
import { Actions, Block, Button, Half, Layout, Third, Title } from '@dragonchain-dev/dragon-kit';
import { Github, Telegram, Youtube } from '@dragonchain-dev/dragon-kit-icons';
import { Layout as PageLayout, SEO } from '../../components/_index';

const Community = () => {
  const { IMAGES_URI } = process.env;

  return (
    <PageLayout>
      <SEO
        title="TIME | Dragonchain Blockchain"
        description="Keep track of your TIME and your current rankings in the global leaderboard."
      />
      <Layout dark background="blue" header="Welcome to the Dragonchain Community">
        <Block type="thirds">
          <Third icon={Github}>
            <Title tag="h3">Github</Title>
            <p>
              Run by the Dragonchain community, here you will find projects built for and by the community to
              experience the power of Dragonchain tech.
            </p>
            <Button blank color="white" hover="blue" to="https://github.com/Dragonchain-Community">
              Go to Github
            </Button>
          </Third>
          <Third icon={Youtube}>
            <Title tag="h3">YouTube</Title>
            <p>
              Tune into our live show, Super Happy Dragon Lucky, every Tuesday. Each week we discuss recent blockchain
              news, including the latest on Dragonchain and Den.
            </p>
            <Button blank color="white" hover="blue" to="https://www.youtube.com/c/DragonchainOfficial">
              Go to YouTube
            </Button>
          </Third>
        </Block>
      </Layout>
      <Layout dark background="fire-dark">
        <Block type="halves" reverse>
          <Half
            illustration={{
              center: true,
              src: `${IMAGES_URI}/brand/den-icon.svg`,
            }}
          />
          <Half>
            <Title tag="h3">Den</Title>
            <Title>Den, Social Media Reinvented</Title>
            <p>
              Grow as a content creator, evaluate the community, or build and benefit from communities, all
              decentralized and powered by the Dragonchain platform.
            </p>
            <Button blank outline to="https://den.social/">
              Go to Den
            </Button>
          </Half>
        </Block>
      </Layout>
      <Layout dark background="blue-dark">
        <Block center>
          <Title>TIME</Title>
          <p>
            TIME influences the user’s access to features within the Dragonchain ecosystem. It takes into
            account both the Dragon balance and length of time each Dragon is held. The number of Dragons
            held, multiplied by the number of days held yields the TIME.
          </p>
          <Button outline to="/community/time">
            View Leaderboard
          </Button>
        </Block>
        <Block divider type="halves">
          <Half
            center
            stack
            illustration={{
              src: `${IMAGES_URI}/illustrations/call-to-action-validator@2x.png`,
            }}
          >
            <Title>Become a validator</Title>
            <p>Run your own Dragon Net validator node or setup your own node in the cloud in minutes.</p>
            <Actions
              actions={[
                {
                  name: 'Cloud',
                  outline: true,
                  blank: true,
                  to: 'https://console.dragonchain.com',
                },
                {
                  name: 'Unmanaged',
                  outline: true,
                  blank: true,
                  to: 'https://github.com/Dragonchain-Community/dragonchain-uvn-install-guide',
                },
              ]}
            />
          </Half>
          <Half
            center
            stack
            illustration={{
              src: `${IMAGES_URI}/illustrations/call-to-action-metrics@2x.png`,
            }}
          >
            <Title>Network metrics</Title>
            <p>View Dragonchain network metrics and the impact to your validator node rewards.</p>
            <Button blank outline to="https://metrics.dragonchain.com">
              Daily Metrics
            </Button>
          </Half>
        </Block>
      </Layout>
    </PageLayout>
  );
};

export default Community;
